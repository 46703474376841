<template>
  <div>
    <b-row>
      <b-col cols="12">
        <Ampliaciones
          :columns-autorizadas="columnasAutorizadas"
          :origen="'autorizadas'"
          :options="true"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import Ampliaciones from '@/views/inspecciones/ampliaciones/Ampliaciones.vue'

export default {
  components: {
    Ampliaciones,
    BRow,
    BCol,
  },
  data() {
    return {
      columnasAutorizadas: [
        {
          label: 'Poste',
          field: 'poste',
        },
        {
          label: 'Etapa',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Creación',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
    }
  },
}
</script>
