<template>
  <div>
    <b-overlay
      :show="show"
    >
      <div class="custom-search">
        <!-- advance search input -->
        <b-row>
          <b-col
            v-if="origen === 'ampliaciones'"
            cols="3"
          >
            <b-form-group>
              <br>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="recreateAmpliacion"
              >
                <feather-icon icon="PlusIcon" />
                Ingresar Nueva Ampliación
              </b-button>
            </b-form-group>
          </b-col>
          <b-col
            v-if="origen === 'autorizadas'"
            cols="1"
          />
          <b-col
            v-if="!isMobile()"
            cols="2"
          >
            <b-form-group v-if="origen === 'ampliaciones'">
              <label>Etapa:</label>
              <v-select
                v-model="etapaBusqueda"
                label="title"
                :options="etapas"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isMobile()"
            cols="3"
          >
            <b-form-group>
              <label>Fecha Inicio:</label>
              <b-input-group class="mb-1">
                <b-form-input
                  id="example-input"
                  v-model="fechaInicio"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="fechaInicio"
                    show-decade-nav
                    button-only
                    size="sm"
                    right
                    locale="en-US"
                    aria-controls="example-input"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isMobile()"
            cols="3"
          >
            <b-form-group>
              <label>Fecha Fin:</label>
              <b-input-group class="mb-1">
                <b-form-input
                  id="fecha-fin"
                  v-model="fechaFin"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="fechaFin"
                    show-decade-nav
                    button-only
                    right
                    size="sm"
                    locale="en-US"
                    aria-controls="example-input"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isMobile()"
            cols="1"
          >
            <br>
            <table>
              <tr>
                <td
                  v-if="origen === 'autorizadas'"
                  style="padding-top: 4px; padding-right: 5%;"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    style="width: 201px"
                    variant="success"
                    class="btn btn-primary float-right"
                    @click="asignarEncargado"
                  >
                    <feather-icon icon="UserPlusIcon" />
                    Asignar Ampliación
                  </b-button>
                </td>
                <td style="padding-top: 4px;">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.v-primary
                    variant="primary"
                    class="btn-icon"
                    title="Buscar"
                    @click="loadReport()"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </td>
                <td style="padding-top: 4px;">
                  <reporte-ampliaciones :ampliaciones-data="ampliacionesData" />
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <!-- table -->
            <vue-good-table
              :columns="columnsAutorizadas || columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :search-options="{
                enabled: true,
                placeholder: 'Buscar...',
              }"
              :select-options="selectOptions()"
              style-class="vgt-table condensed"
              @on-row-dblclick="showDetalle"
              @on-selected-rows-change="selectionChanged"
              @on-select-all="onSelectAll"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Action -->
                <span v-if="props.column.field === 'action'">
                  <span>
                    <b-dropdown
                      v-if="props.row.estado === 'ASIGNADA' || props.row.estado === 'AUTORIZACION' || props.row.estado === 'RECHAZADA' || props.row.estado === 'FINALIZADA' || props.row.estado === 'FACTURADO' || props.row.estado === 'EN CURSO' || props.row.estado === 'AUTORIZADO'"
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item
                        v-if="props.row.estado === 'ASIGNADA' || props.row.estado === 'EN CURSO'"
                        @click="reasignarEncargado(props)"
                      >
                        <feather-icon
                          icon="UserPlusIcon"
                          class="mr-50"
                        />
                        <span>Reasignar Encargado</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="props.row.estado === 'AUTORIZACION' || props.row.estado === 'RECHAZADA'"
                        @click="onRowClick(props)"
                      >
                        <feather-icon
                          icon="Edit2Icon"
                          class="mr-50"
                        />
                        <span>Editar</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="usuario.role === 'ADMIN' && props.row.estado !== 'FINALIZADA' && props.row.estado !== 'COBRADA' && props.row.estado !== 'FACTURADO'"
                        @click="anularAmpliacion(props)"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          class="mr-50"
                        />
                        <span>Eliminar Ampliación</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="props.row.estado === 'FINALIZADA' || props.row.estado === 'FACTURADO'"
                        @click="ingresarValoresReporte(props.row)"
                      >
                        <feather-icon
                          icon="PenToolIcon"
                          class="mr-50"
                        />
                        <span>{{ generarReporte(props.row) ? 'Cambiar Valores Informe' : 'Ingresar Valores Informe' }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="generarReporte(props.row) && (props.row.estado === 'FINALIZADA' || props.row.estado === 'FACTURADO')"
                        @click="generarDocumento(props.row)"
                      >
                        <feather-icon
                          icon="DownloadIcon"
                          class="mr-50"
                        />
                        <span>Descargar Informe</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
                <!-- Column: Poste -->
                <span
                  v-else-if="props.column.field === 'poste'"
                  style="font-size: 13px"
                >
                  &nbsp;&nbsp;<b>{{ props.formattedRow[props.column.field] }}</b>
                </span>
                <!-- Column: Fecha -->
                <span
                  v-else-if="props.column.field === 'fechaInstalacion'"
                  style="font-size: 13px"
                >
                  {{ props.row.fechaRealInstalacion ? formatDate(props.row.fechaRealInstalacion, '/') : props.row.fechaInstalacion }}
                </span>
                <!-- Column: Common -->
                <span
                  v-else
                  style="font-size: 12px"
                >
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Mostrar </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '15', '20']"
                      class="mx-1"
                      @input="
                        (value) => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap"> de {{ props.total }} registros </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>
      <!---- MODALES ---->
      <!--<form-ampliaciones />-->

      <b-modal
        id="modal-lg"
        ref="my-modal"
        v-model="dialog"
        hide-footer
        centered
        size="lg"
        modal-class="modal-primary"
        title="Formulario Ampliaciones"
        no-close-on-backdrop
      >
        <form-ampliaciones
          :ampliacion="ampliacion"
          :is-mobile="isMobile()"
          @reload-ampliaciones="loadReport()"
        />
      </b-modal>

      <!--ingreso campos ampliacion/>-->
      <b-modal
        id="modal-lg"
        ref="my-modal"
        v-model="dialogIngresoValores"
        ok-only
        centered
        size="xs"
        no-close-on-backdrop
        modal-class="modal-primary"
        title="Valores Reporte"
        @ok="guardarCorrelativos"
      >
        <b-row>
          <b-col cols="12">
            <label>Correlativo del Item</label>
            <b-form-input
              v-model.number="ampliacion.noItem"
              required
            />
          </b-col>
          <b-col cols="12">
            <label>Orden Ampliación</label>
            <b-form-input
              v-model="ampliacion.ordenAmpliacion"
              required
            />
          </b-col>
          <b-col cols="12">
            <label>Fecha de Instalación</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="timePickerInstalacion"
                v-model="ampliacion.fechaRealInstalacion"
                type="text"
                placeholder="AAAA-MM-DD"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="ampliacion.fechaRealInstalacion"
                  show-decade-nav
                  size="sm"
                  button-only
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label>Mes de Informe:</label>
              <v-select
                v-model="ampliacion.mesInformeAmpliacion"
                label="nombre"
                :options="meses"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>

      <!--<detalle ampliacion />-->
      <b-modal
        id="modal-ampliacion"
        ref="modal-ampliacionl"
        v-model="dialogModalAmpliacion"
        hide-footer
        centered
        size="lg"
        modal-class="modal-primary"
        title="Detalle Ampliación"
        no-close-on-backdrop
      >
        <detalle-ampliacion :ampliacion="ampliacion" />
      </b-modal>

      <!-- Modal para Asignar Encargado -->
      <b-modal
        id="modal-asignar-encargado"
        ref="modal-asignar-encargado"
        v-model="dialogAsignarEncargado"
        :title="tipoOperacion === 'ASIGNADA' ? 'Asignar Encargado' : 'Reasignar Encargado'"
        ok-title="Guardar"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        cancel-title="Cancelar"
        cancel-variant="outline-secondary"
        @ok="handleOk"
      >
        <form @submit.stop.prevent="handleSubmit">
          <b-form-group
            label-for="name-input"
            invalid-feedback="Debe seleccionar un usuario para asignar la ampliación"
          >
            <h5 v-if="tipoOperacion === 'ASIGNADA'">
              Asignar Encargado
            </h5>
            <h5 v-else>
              Reasignar Encargado
            </h5>
            <v-select
              v-model="usuarioAsignar"
              :state="usuarioState"
              :options="usuarios"
              label="nombre"
            />
          </b-form-group>
        </form>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import FormAmpliaciones from '@/components/FormAmpliaciones.vue'
import { getItem } from '@/utils/catalogos'
import {
  BButton,
  BCol,
  VBTooltip,
  BRow,
  BFormDatepicker,
  BInputGroup,
  BFormGroup,
  BInputGroupAppend,
  BFormInput,
  BFormSelect,
  BPagination,
  VBPopover,
  VBModal,
  BDropdown,
  BDropdownItem, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import { findAmplicacionId, findAmpliaciones, updateCreateAmpliacion } from '@/utils/inspecciones'
import {
  formatDate, obtenerFechaInicioFin, sumarDiasFecha, getListadoMeses,
} from '@/utils/fechas'
import 'vue-good-table/dist/vue-good-table.css'
import { generarResumenAmplicacion, generatePDF, ordernarItemsAmpliacion } from '@/utils/files'
import DetalleAmpliacion from '@/components/Inspecciones/ampliaciones/DetalleAmpliacion.vue'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import ReporteAmpliaciones from '@/components/reportes/reporteAmpliaciones.vue'
import { getUsersByCompany } from '@/utils/usuarios'

export default {
  components: {
    ReporteAmpliaciones,
    DetalleAmpliacion,
    BButton,
    BCol,
    BRow,
    BFormDatepicker,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    vSelect,
    BFormInput,
    VueGoodTable,
    BFormSelect,
    BPagination,
    FormAmpliaciones,
    BDropdown,
    BDropdownItem,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    'b-modal': VBModal,
  },
  props: {
    datosTabla: {
      type: Array,
      default: () => [],
    },
    origen: {
      type: String,
      default: 'ampliaciones',
    },
    columnsAutorizadas: {
      type: Array,
      default: null,
    },
    options: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formatDate,
      show: false,
      ampliacionesData: [],
      dialogModalAmpliacion: false,
      etapas: [
        { title: 'Todas', value: '' },
        { title: 'En Autorización', value: 'AUTORIZACION' },
        { title: 'Rechazadas', value: 'RECHAZADA' },
        { title: 'Autorizadas / Pendientes', value: 'AUTORIZADO' },
        { title: 'Asignadas', value: 'ASIGNADA' },
        { title: 'En Curso', value: 'EN CURSO' },
        { title: 'Finalizadas', value: 'FINALIZADA' },
        { title: 'Facturadas', value: 'FACTURADO' },
        { title: 'Cobradas', value: 'COBRADA' },
        { title: 'Anuladas', value: 'ANULADA' },
      ],
      fechaInicio: '',
      fechaFin: '',
      etapaBusqueda: '',
      rows: [],
      columns: [
        {
          label: 'Poste',
          field: 'poste',
        },
        {
          label: 'Etapa',
          field: 'estado',
        },
        {
          label: 'Creación',
          field: 'fechaCreacion',
        },
        {
          label: 'Responsable',
          field: 'usuario.nombre',
        },
        {
          label: 'Instalación',
          field: 'fechaInstalacion',
        },
        {
          label: 'Acciones',
          field: 'action',
        },
      ],
      usuario: JSON.parse(localStorage.getItem('userData')),
      searchTerm: '',
      perPage: 5,
      totalRows: 1,
      currentPage: 1,
      pageLength: 15,
      dialog: false,
      dialogIngresoValores: false,
      ampliacionclean: {
        poste: '',
        municipio: {
          id: 15,
          nombre: 'VILLA NUEVA',
          codigoRenap: '0115',
          active: true,
          idDepartamento: 1,
        },
        localidad: null,
        usuario: null,
        calleAvenida: '',
        numeral: '',
        latitud: '',
        longitud: '',
        estado: 'AUTORIZACION',
        fechaCreacion: new Date(),
        comentarios: '',
        metros: 0,
        companyId: JSON.parse(localStorage.getItem('userData')).idCompany,
        tracking: [],
        puntoExistente: false,
        items: [
          {
            id: 1,
            options: [],
            tipo: null,
            item: null,
          },
        ],
      },
      ampliacion: null,
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      usuarioAsignar: null,
      usuarioState: null,
      dialogAsignarEncargado: false,
      usuarios: [],
      seleccionadas: [],
      selectedRows: [],
      tipoOperacion: '',
      tipoOperacionModal: null,
    }
  },
  async created() {
    this.rows = []
    this.seleccionadas = []
    this.meses = getListadoMeses(1)
    const fechas = obtenerFechaInicioFin()
    this.fechaFin = fechas.fechaFin
    this.fechaInicio = fechas.fechaInicio
    await this.loadReport()
    this.show = false
    this.ampliacion = JSON.parse(JSON.stringify(this.ampliacionclean))
    this.ampliacion.items[0].item = await getItem(10, 2)
    this.ampliacion.items[0].options.push(this.ampliacion.items[0].item)
    this.usuarios = await getUsersByCompany(this.usuario.idCompany)
    this.usuarios.map(usuario => {
      // eslint-disable-next-line no-param-reassign
      usuario.nombre = `${usuario.firstName} ${usuario.firstSurname}`
      return usuario
    })
  },
  methods: {
    selectOptions() {
      return this.options
        ? {
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }
        : { enabled: false }
    },
    asignarEncargado() {
      if (this.seleccionadas.length === 0) return
      this.tipoOperacionModal = 'ASIGNADA'
      this.dialogAsignarEncargado = true
    },
    async reasignarEncargado(props) {
      this.tipoOperacionModal = 'REASIGNAR'
      const ampliacionSeleccionada = props.row
      this.ampliacion = ampliacionSeleccionada
      this.dialogAsignarEncargado = true
      this.usuarioAsignar = null
      this.seleccionadas = [ampliacionSeleccionada]
    },
    async handleSubmit() {
      try {
        if (!this.usuarioAsignar && this.tipoOperacionModal === 'ASIGNADA') {
          mensajeError('Debe seleccionar un usuario encargado para la(s) ampliación(es).')
          return
        } if (!this.usuarioAsignar && this.tipoOperacionModal === 'REASIGNAR') {
          mensajeError('Debe seleccionar un usuario encargado para la ampliación.')
          return
        }
        if (this.tipoOperacionModal === 'ASIGNADA') {
          // eslint-disable-next-line no-restricted-syntax
          for await (const ampliacion of this.seleccionadas) {
            ampliacion.estado = 'ASIGNADA'

            ampliacion.usuario = {
              id: this.usuarioAsignar.id,
              nombre: `${this.usuarioAsignar.firstName} ${this.usuarioAsignar.firstSurname}`,
              name: `${this.usuarioAsignar.firstName} ${this.usuarioAsignar.firstSurname}`,
              email: this.usuarioAsignar.email,
            }
            ampliacion.tracking.push({
              localization: this.localization,
              tipo: 'Ampliación Asignada',
              fechaHora: new Date(),
              usuario: {
                id: this.usuario.id,
                nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
              },
            })

            delete ampliacion.grupo
            delete ampliacion.fechaInstalacion
            delete ampliacion.fechaCreacion
            delete ampliacion.vgtSelected
            delete ampliacion.vgt_id
            delete ampliacion.originalIndex

            await updateCreateAmpliacion(ampliacion, 1)
          }
        } else if (this.tipoOperacionModal === 'REASIGNAR') {
          const ampliacion = this.seleccionadas[0] // Utilizamos solo la ampliación seleccionada
          if (ampliacion) {
            ampliacion.usuario = {
              id: this.usuarioAsignar.id,
              nombre: `${this.usuarioAsignar.firstName} ${this.usuarioAsignar.firstSurname}`,
              name: `${this.usuarioAsignar.firstName} ${this.usuarioAsignar.firstSurname}`,
              email: this.usuarioAsignar.email,
            }
            ampliacion.tracking.push({
              localization: this.localization,
              tipo: 'Ampliación Reasignada',
              fechaHora: new Date(),
              usuario: {
                id: this.usuario.id,
                nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
              },
            })

            delete ampliacion.grupo
            delete ampliacion.fechaInstalacion
            delete ampliacion.fechaCreacion
            delete ampliacion.vgtSelected
            delete ampliacion.vgt_id
            delete ampliacion.originalIndex

            await updateCreateAmpliacion(ampliacion, 1)
          }
        }

        this.seleccionadas = []
        await this.loadReport()

        const mensajeTitulo = this.tipoOperacionModal === 'ASIGNADA' ? 'Encargado Asignado' : 'Encargado Reasignado!'
        const mensajeTexto = this.tipoOperacionModal === 'ASIGNADA' ? 'Se asignó correctamente el encargado a la ampliación.' : 'Se reasigno correctamente la(s) ampliación(es).'
        mensajeInformativo(mensajeTitulo, mensajeTexto)

        this.dialogAsignarEncargado = false
      } catch (error) {
        console.error(error)
        mensajeError('Ocurrió un error al asignar el encargado. Intente nuevamente.')
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    selectionChanged(params) {
      this.seleccionadas = params.selectedRows
    },
    onSelectAll(params) {
      this.seleccionadas = params.selectedRows
    },
    async anularAmpliacion(params) {
      this.$bvModal
        .msgBoxConfirm('Está seguro de eliminar la ampliación seleccionada?', {
          title: 'Confirmación de Eliminación',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Si',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          try {
            if (value) {
              if (typeof params === 'object') this.ampliacion = await findAmplicacionId(params.row.id)
              else this.ampliacion = await findAmplicacionId(params)

              this.ampliacion.estado = 'ANULADA'
              this.ampliacion.tracking.push({
                localization: this.localization,
                tipo: 'Ampliación Anulada',
                comentarios: '',
                fechaHora: new Date(),
                usuario: {
                  id: this.usuario.id,
                  nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
                },
              })
              delete this.ampliacion.originalIndex
              delete this.ampliacion.vgtSelected
              delete this.ampliacion.vgt_id
              delete this.ampliacion.grupo
              delete this.ampliacion.fechaInstalacion
              delete this.ampliacion.fechaCreacion
              await updateCreateAmpliacion(this.ampliacion, 1)
              await this.loadReport()
            }
          } catch (err) {
            console.error('error eliminando ', err)
            this.error('Ocurrió un incoveniente eliminando la ampliaciones, intente nuevamente!')
          }
        })
    },
    async recreateAmpliacion() {
      this.ampliacion = JSON.parse(JSON.stringify(this.ampliacionclean))
      this.ampliacion.items = [{
        id: 1,
        options: [],
        tipo: {
          id: 10,
          catalogo: 'unidades_varias',
          nombre: 'Otros',
        },
        item: null,
      },
      ]
      this.ampliacion.items[0].item = await getItem(10, 2)
      this.ampliacion.items[0].options.push(this.ampliacion.items[0].item)
      this.dialog = true
    },
    isMobile() {
      if (window.screen.width <= 760) {
        return true
      }
      return false
    },
    async ingresarValoresReporte(params) {
      if (typeof params === 'object') this.ampliacion = await findAmplicacionId(params.id)
      else this.ampliacion = await findAmplicacionId(params)
      this.dialogIngresoValores = true
    },
    async guardarCorrelativos() {
      if (this.ampliacion.noItem && this.ampliacion.noItem.toString().trim() === '') {
        mensajeError('Debe Ingresar el Correlativo del Item !')
        return
      }

      if (this.ampliacion.ordenAmpliacion && this.ampliacion.ordenAmpliacion.trim() === '') {
        mensajeError('Debe Ingresar la Orden de la Ampliación !')
        return
      }

      if (this.ampliacion.fechaRealInstalacion && this.ampliacion.fechaRealInstalacion === '') {
        mensajeError('Debe Ingresar la Fecha de Instalación de la Ampliación !')
        return
      }
      if (this.ampliacion.mesInformeAmpliacion && this.ampliacion.mesInformeAmpliacion === '') {
        mensajeError('Debe Ingresar el Mes para el Informe!')
        return
      }

      const body = {
        id: this.ampliacion.id,
        noItem: this.ampliacion.noItem.toString(),
        ordenAmpliacion: this.ampliacion.ordenAmpliacion,
        fechaRealInstalacion: this.ampliacion.fechaRealInstalacion,
        mesInformeAmpliacion: this.ampliacion.mesInformeAmpliacion,
      }
      const resultado = await updateCreateAmpliacion(body, 3)
      if (resultado !== []) {
        mensajeInformativo('Ampliación Actualizada', 'Ampliación Actualizada Correctamente!!')
        this.loadReport()
        this.dialogIngresoValores = false
      } else {
        mensajeError('Ocurrió un error actualizando la Ampliación!!')
      }
    },
    async generarDocumento(ampliacion) {
      try {
        this.show = true
        const listadoItems = await ordernarItemsAmpliacion(ampliacion)
        const fileResumen = await generarResumenAmplicacion(ampliacion, listadoItems)
        if (fileResumen !== null) {
          const linkSource = `data:application/pdf;base64,${fileResumen.Base64}`
          const downloadLink = document.createElement('a')
          const fileName = `ampliacion_${ampliacion.id}_resumen.pdf`
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
        // eslint-disable-next-line no-restricted-syntax
        for await (const item of listadoItems) {
          const result = await generatePDF(ampliacion, item)
          if (result !== null) {
            const linkSource = `data:application/pdf;base64,${result.Base64}`
            const downloadLink = document.createElement('a')
            const fileName = `ampliacion_${ampliacion.id}_${item.tipo.nombre}.pdf`
            downloadLink.href = linkSource
            downloadLink.download = fileName
            downloadLink.click()
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.show = false
      }
    },
    generarReporte(row) {
      const noItemValido = row.noItem && row.noItem !== ''
      const ordenAmpliacionValido = row.ordenAmpliacion && row.ordenAmpliacion.trim() !== ''
      const resultado = noItemValido && ordenAmpliacionValido
      return resultado !== '' ? resultado : false
    },
    async onRowClick(params) {
      if (typeof params === 'object') this.ampliacion = await findAmplicacionId(params.row.id)
      else this.ampliacion = await findAmplicacionId(params)
      this.dialog = true
    },
    async showDetalle(params) {
      if (typeof params === 'object') this.ampliacion = await findAmplicacionId(params.row.id)
      else this.ampliacion = await findAmplicacionId(params)
      this.dialogModalAmpliacion = true
    },
    async loadReport() {
      this.show = true
      this.dialog = false
      this.ampliacion = this.ampliacionclean
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].hide()
      })

      const fechaInicio = new Date(this.fechaInicio)
      const fechaFin = sumarDiasFecha(new Date(this.fechaFin), 2)

      const filter = {
        where: {
          and: [
            { fechaCreacion: { gte: fechaInicio.toISOString() } },
            { fechaCreacion: { lte: fechaFin.toISOString() } },
            { companyId: this.usuario.idCompany },
            { estado: { neq: 'ANULADA' } },
          ],
        },
        order: ['fechaCreacion ASC'],
      }

      if (this.origen === 'autorizadas') {
        filter.where.and.push({ estado: 'AUTORIZADO' })
      }

      if (this.etapaBusqueda.value !== '') {
        filter.where.and.push({ estado: this.etapaBusqueda.value })
      }

      const data = await findAmpliaciones(filter)
      this.show = false
      // eslint-disable-next-line no-restricted-syntax
      for await (const item of data) {
        item.fechaAtencion = item.tracking.length > 0
          ? formatDate(item.tracking[0].fechaHora, '/')
          : ''
        item.fechaCreacion = formatDate(item.fechaCreacion, '/')
        if (item.fechaInstalacion) item.fechaInstalacion = formatDate(item.fechaInstalacion, '/')
        // item.ruta = await getItem(8, item.rutaId)
      }
      this.ampliacionesData = data
      this.rows = data
      this.show = false
    },
  },
}
</script>
